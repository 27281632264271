const LocalStorageKeys = {
  joinRoomTries: "JoinRoomTries",
  joinRoomBlockTime: "JoinRoomBlockTime",
};

class LocalStorageManager {
  private static instance: LocalStorageManager;
  public static getInstance(): LocalStorageManager {
    if (!LocalStorageManager.instance) {
      LocalStorageManager.instance = new LocalStorageManager();
    }

    return LocalStorageManager.instance;
  }

  setJoinRoomTries(tries: number) {
    localStorage.setItem(LocalStorageKeys.joinRoomTries, tries.toString());
  }

  getJoinRoomTries(): number | undefined {
    const result = localStorage.getItem(LocalStorageKeys.joinRoomTries);
    if (result) {
      return Number(result);
    }
  }

  setJoinRoomBlockTime(time: number) {
    localStorage.setItem(LocalStorageKeys.joinRoomBlockTime, time.toString());
  }

  getJoinRoomBlockTime(): number | undefined {
    const result = localStorage.getItem(LocalStorageKeys.joinRoomBlockTime);
    if (result) {
      return Number(result);
    }
  }
}

export default LocalStorageManager;