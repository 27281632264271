
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { v4 as uuid4 } from "uuid";
import { generateWsUrl } from "./webSocketUrl";
import { createAppSyncGraphQLOperationAdapter } from "./graphQLAdaptor";

const APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS = 5 * 60 * 1000;

/**
 * Creates a websocket link for the specified endpoint and auth properties.
 * 
 * @param {String} realTimeEndpoint The websocket (wss:) endpoint to connect to
 * @param {*} authHeader Header properties for authentication
 * @returns 
 */
function createWebSocketLink(realTimeEndpoint, authHeader) {

    // AWS advises uses a UUID as operation identifier (rather than incrementing number)
    class UUIDOperationIdSubscriptionClient extends SubscriptionClient {
        generateOperationId() {
            return uuid4();
        }
    }

    const uri = generateWsUrl(realTimeEndpoint, authHeader, {});

    const getAppSyncAuthorizationInfo = async () => authHeader;

    /*
    * TODO: AppSync acknowledges GraphQL subscriptions with "start_ack" messages
    * but SubscriptionClient cannot handle them. It should possible to provide a 
    * custom WebSocket to the SubscriptionClient that filters out the "start_ack"
    * messages.
    */

    return new WebSocketLink(
        new UUIDOperationIdSubscriptionClient(
            uri,
            { timeout: APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS, reconnect: true, lazy: true }
        ).use([createAppSyncGraphQLOperationAdapter(getAppSyncAuthorizationInfo)])
    );
}


export {
    createWebSocketLink
};