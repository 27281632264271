import { ApiDetails } from "../types/apiDetails";

class EnvironmentManager {
  private static instance: EnvironmentManager;
  public static getInstance(): EnvironmentManager {
    if (!EnvironmentManager.instance) {
      EnvironmentManager.instance = new EnvironmentManager();
    }

    return EnvironmentManager.instance;
  }

  //TODO This is not secure way to keep API Details. 
  getApiDetails(): ApiDetails {
    return {
      graphQLEndpoint:
        "https://api.test.watchtogether.cherry-dev.aidisco.sky.com/graphql",
      realtimeGraphQLEndpoint:
        "wss://api.test.watchtogether.cherry-dev.aidisco.sky.com/graphql/realtime",
      apiKey: "da2-ngkjmeuevvfe5b6g6vc4dthtwy",
    };
  }
}

export default EnvironmentManager;
