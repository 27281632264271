import { getMainDefinition } from "@apollo/client/utilities";
import { split } from "@apollo/client";

/**
 * Ensure that HTTP is used for queries and mutations, but websockets for subscriptions 
 * @param {*} httpLink The Http link
 * @param {*} websocketLink The websocket link
 * @returns A new link
 */
function createSplitLink(httpLink, websocketLink) {
    return split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
            );
        },
        websocketLink,
        httpLink
    );
}

export {
    createSplitLink
};