import { QXVPApiClient } from "../api/QXVPApiClient";

export async function searchContetApi(text: string) {
  const result = await QXVPApiClient.getInstance().search(text);
  /*const unique = [
    ...new Map(result.map((item: any) => [item["uuid"], item])).values(),
  ];

  unique.sort(function (a: any, b: any) {
    return a.t.localeCompare(b.t);
  });

  return unique;*/
}
