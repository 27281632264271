import { ApiClient } from "../api/ApiClient";
import { ApiDetails } from "../types/apiDetails";
import { Room } from "../types/room";
import { getApiDetails } from "./getApiDetails";
import { getRoomApi } from "./getRoom";

export interface JoinRoomParameters {
  roomCode: string;
  loadRoom: boolean;
}
export async function joinRoomApi(joinRoomParameters: JoinRoomParameters) {
  const apiDetails = getApiDetails();
  const apiClient = ApiClient.getInstance(apiDetails);

  const nickname = generateRandom(10);
  const serialNumber = generateRandom(10);

  const cameraHardwareEnabled = true;
  const micEnabled = true;
  const cameraEnabled = true;

  const input = {
    nickname,
    roomCode: joinRoomParameters.roomCode,
    serialNumber: serialNumber,
    cameraHardwareEnabled,
    micEnabled,
    cameraEnabled,
  };
  let participantDetails = await apiClient.joinRoom(input);
  let room: Room | undefined;
  if (joinRoomParameters.loadRoom) {
    room = await getRoomApi({
      roomCode: joinRoomParameters.roomCode,
    });
  }
  return { participantDetails: participantDetails, room: room };
}

function generateRandom(length: number) {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}