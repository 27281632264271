import { WTCallClient } from "../call/WTCallClient";
import { ParticipationDetails } from "../types/participationDetails";
import { Room } from "../types/room";

export interface InitCallParameters {
  room: Room;
  participationDetails: ParticipationDetails;
}
export async function initCallApi(initCallParameters: InitCallParameters) {
  await WTCallClient.getInstance().init(
    initCallParameters.room,
    initCallParameters.participationDetails
  );
}
