declare global {
  interface Window {
    LOG_LEVEL: string;
  }
}

// To turn on debug logging, type this in the console or uncomment to get DEBUG all the time
window.LOG_LEVEL = "INFO";

class Logger {
  debug(...msg: any) {
    console.log(msg);
  }

  info(...msg: any) {
    console.log(msg);
  }

  warn(...msg: any) {
    console.log(msg);
  }

  error(...msg: any) {
    console.log(msg);
  }
}

const LOG = new Logger();
export default LOG;
