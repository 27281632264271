import { ApiClient } from "../api/ApiClient";
import { Room } from "../types/room";
import { getApiDetails } from "./getApiDetails";


export interface UpdateRoomParameters {
  room: Room;
  entityId?: string | undefined;
  appContentData?: string | undefined;
  providerId?: string | undefined;
  isPlaying?: boolean | undefined;
  playbackOffset?: number | undefined;
}
export async function updateRoomApi(updateRoomParams: UpdateRoomParameters) {
  const apiDetails = getApiDetails();
  const apiClient = ApiClient.getInstance(apiDetails);
  const room = updateRoomParams.room;
  const input = {
    entityId:
      updateRoomParams.entityId !== undefined
        ? updateRoomParams.entityId
        : room.entityId,
    appContentData:
      updateRoomParams.appContentData !== undefined
        ? updateRoomParams.appContentData
        : room.appContentData,
    providerId:
      updateRoomParams.providerId !== undefined
        ? updateRoomParams.providerId
        : room.providerId,
    isPlaying:
      updateRoomParams.isPlaying !== undefined
        ? updateRoomParams.isPlaying
        : room.isPlaying,
    playbackOffset:
      updateRoomParams.playbackOffset !== undefined
        ? updateRoomParams.playbackOffset
        : room.playbackOffset,
  };
  const result = await apiClient.updateRoom(room.roomCode, input);

  if (result.room) {
    return true;
  } else {
    return false;
  }
}
