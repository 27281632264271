import { ApiClient } from "../api/ApiClient";
import { WTCallClient } from "../call/WTCallClient";
import { ApiDetails } from "../types/apiDetails";
import { ParticipationDetails } from "../types/participationDetails";
import { getApiDetails } from "./getApiDetails";

export interface SetMicrophoneStateParameters {
  participationDetails: ParticipationDetails;
  state: boolean;
}

export async function setMicrophoneStateApi(
  setMicrophoneStateParameters: SetMicrophoneStateParameters
) {
  await WTCallClient.getInstance().setMicrophoneState(
    setMicrophoneStateParameters.state
  );
  const input = { micEnabled: setMicrophoneStateParameters.state };
  const apiDetails = getApiDetails();
  await ApiClient.getInstance(apiDetails).updateParticipant(
    input,
    setMicrophoneStateParameters.participationDetails.participant.id
  );
  return setMicrophoneStateParameters.state;
}
