import { Fragment, memo, useEffect, useRef, useState } from "react";
import {
  Box,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
  Typography,
} from "@mui/material";

import "./CallingPage.css";
import {
  FluentThemeProvider,
  VideoGallery,
  VideoGalleryStyles,
  darkTheme,
} from "@azure/communication-react";

import SectionCard from "../../components/SectionCard";
import "./CallingPage.css";

import { useAppDispatch, useAppSelector } from "../../app/hooks";

import {
  addParticipantEvent,
  addRoomEvent,
  getMetadata,
  initCall,
  joinRoom,
  subscribeToParticipantUpdate,
  subscribeToRoomUpdate,
  selectCallingState,
  setCameraState,
  setMicrophoneState,
  setRoomCode,
  updateRoom,
  CallingState,
  hangUpCall,
  onParticipantChanged,
} from "./slices/callingSlice";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import {
  Close,
  Mic,
  MicOff,
  PlayCircle,
  Videocam,
  VideocamOff,
} from "@mui/icons-material";

import RemoteControlDialog from "../../components/calling/RemoteControlDialog";
import { WTCallClient } from "../../call/WTCallClient";
import { RemoteParticipant } from "../../types/participant";

function getSpeedDialActions(callingState: CallingState) {
  var actions = [];

  actions.push({ key: "hangup", icon: <Close />, name: "Hangup" });
  actions.push({
    key: "camera",
    icon: callingState.cameraEnabled ? <Videocam /> : <VideocamOff />,
    name: callingState.cameraEnabled ? "Disable cam" : "Enable cam",
  });
  actions.push({
    key: "mic",
    icon: callingState.microphoneEnabled ? <Mic /> : <MicOff />,
    name: callingState.microphoneEnabled ? "Disable mic" : "Enable mic",
  });

  if (
    callingState.contentMetadata &&
    Object.keys(callingState.contentMetadata).length > 0
  ) {
    actions.push({ key: "rc", icon: <PlayCircle />, name: "Remote Control" });
  }

  return actions;
}

const CallingPage = memo(() => {
  const callingState = useAppSelector(selectCallingState) as CallingState;
  const dispatch = useAppDispatch();
  const [errorOpen, setErrorOpen] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [remoteControlDialogOpen, setRemoteControlDialogOpen] = useState(false);
  const initialised = useRef(false);
  const localView = callingState.localParticipant.hasView
    ? WTCallClient.getInstance().getView("local")
    : undefined;

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let roomCode = params.get("roomCode") ?? "";
      dispatch(setRoomCode(roomCode));
    }
  }, []);

  useEffect(() => {
    if (callingState.roomCode) {
      dispatch(
        joinRoom({
          roomCode: callingState.roomCode,
          loadRoom: true,
        })
      );
    }
  }, [callingState.roomCode, dispatch]);

  useEffect(() => {
    if (callingState.room) {
      dispatch(getMetadata(callingState.room));
    }
  }, [callingState.room, dispatch]);

  useEffect(() => {
    if (callingState.room && !callingState.participationDetails) {
      dispatch(
        joinRoom({
          roomCode: callingState.room.roomCode,
          loadRoom: false,
        })
      );
    }
  }, [callingState.room, callingState.participationDetails, dispatch]);

  useEffect(() => {
    if (
      callingState.participationDetails &&
      callingState.room &&
      !callingState.callInitialised
    ) {
      dispatch(
        initCall({
          room: callingState.room,
          participationDetails: callingState.participationDetails,
        })
      );
    }
  }, [
    callingState.participationDetails,
    callingState.room,
    callingState.callInitialised,
    dispatch,
  ]);

  useEffect(() => {
    if (
      callingState.participationDetails &&
      callingState.room &&
      !callingState.roomUpdateSubscriptionInitialised
    ) {
      dispatch(
        subscribeToRoomUpdate({
          roomCode: callingState.room.roomCode,
          callback: (event: any) => {
            dispatch(addRoomEvent(event));
          },
        })
      );

      dispatch(
        subscribeToParticipantUpdate({
          roomCode: callingState.room.roomCode,

          callback: (event: any) => {
            dispatch(onParticipantChanged(event));
          },
        })
      );
    }
  }, [
    callingState.participationDetails,
    callingState.room,
    callingState.roomUpdateSubscriptionInitialised,
    dispatch,
  ]);

  useEffect(() => {
    if (
      callingState.participationDetails &&
      callingState.room &&
      !callingState.participantUpdateSubscriptionInitialised
    ) {
      dispatch(
        subscribeToParticipantUpdate({
          roomCode: callingState.room.roomCode,
          callback: (event: any) => {
            dispatch(addParticipantEvent(event));
          },
        })
      );
    }
  }, [
    callingState.participationDetails,
    callingState.room,
    callingState.participantUpdateSubscriptionInitialised,
    dispatch,
  ]);

  useEffect(() => {
    if (callingState.error) {
      setErrorOpen(true);
    }
  }, [callingState.error]);

  useEffect(() => {
    setLoadingOpen(
      callingState.loading || !callingState.localParticipant.hasView
    );
  }, [callingState.loading, callingState.localParticipant.hasView]);

  useEffect(() => {
    if (!callingState.contentMetadata) {
      setRemoteControlDialogOpen(false);
    }
  }, [callingState.contentMetadata]);

  var localParticipant = {
    userId: "user1",
    displayName: "You",

    isMuted: !callingState.localParticipant.micEnabled,
    videoStream: {},
  };
  if (callingState.localParticipant.cameraEnabled) {
    localParticipant.videoStream = {
      isAvailable: callingState.localParticipant.cameraEnabled,
      isReceiving: callingState.localParticipant.cameraEnabled,
      renderElement: localView?.target,
    };
  }

  const remoteParticpiants = callingState.remoteParticipants.map(
    (participant: RemoteParticipant) => {
      const view = participant.hasView
        ? WTCallClient.getInstance().getView(participant.uuid)
        : undefined;

      return {
        userId: participant.uuid,
        displayName: "Watch Together User",
        isMuted: !participant.micEnabled,
        videoStream:
          view && participant.cameraEnabled
            ? {
                isAvailable: view !== undefined,
                isReceiving: view !== undefined,
                renderElement: view?.target,
              }
            : undefined,
      };
    }
  );

  const customStyles: VideoGalleryStyles = {
    root: {},
    gridLayout: {},
    horizontalGallery: {},
  };

  const containerStyle = { height: "100dvh" };

  return (
    <Stack>
      <LoadingBackdrop open={loadingOpen}></LoadingBackdrop>
      <Fragment>
        {callingState.error ? (
          <SectionCard title="Error">
            <Typography align="center" variant="h5">
              {callingState.error}
            </Typography>
          </SectionCard>
        ) : (
          <div />
        )}
        {localView ? (
          <Stack style={containerStyle}>
            <FluentThemeProvider fluentTheme={darkTheme}>
              <VideoGallery
                onRenderAvatar={(userId, options, defaultOnRender) => {
                  return (options && defaultOnRender?.(options)) ?? <></>;
                }}
                styles={customStyles}
                maxRemoteVideoStreams={12}
                localParticipant={localParticipant}
                remoteParticipants={remoteParticpiants}
                overflowGalleryPosition="verticalRight"
                dominantSpeakers={callingState.dominantSpeakers}
              />
            </FluentThemeProvider>
          </Stack>
        ) : (
          <div />
        )}
      </Fragment>
      {callingState.callInitialised ? (
        <Box className="fab-box" sx={{ "& > :not(style)": { m: 1 } }}>
          <SpeedDial
            ariaLabel="WT control"
            sx={{ position: "absolute", bottom: 4, right: 4 }}
            icon={<SpeedDialIcon />}
          >
            {getSpeedDialActions(callingState).map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => {
                  switch (action.key) {
                    case "hangup":
                      dispatch(hangUpCall());
                      break;
                    case "mic":
                      dispatch(
                        setMicrophoneState({
                          participationDetails:
                            callingState.participationDetails!,
                          state: !callingState.microphoneEnabled,
                        })
                      );
                      break;
                    case "camera":
                      dispatch(
                        setCameraState({
                          participationDetails:
                            callingState.participationDetails!,
                          state: !callingState.cameraEnabled,
                        })
                      );
                      break;
                    case "rc":
                      setRemoteControlDialogOpen(true);
                      break;
                  }
                }}
              />
            ))}
          </SpeedDial>
        </Box>
      ) : (
        <div />
      )}
      {callingState?.room ? (
        <RemoteControlDialog
          open={remoteControlDialogOpen}
          handleClose={() => {
            setRemoteControlDialogOpen(false);
          }}
          onRoomChanged={(
            isPlaying?: boolean | undefined,
            playbackOffset?: number | undefined
          ) => {
            dispatch(
              updateRoom({
                room: callingState.room!,
                isPlaying: isPlaying,
                playbackOffset: playbackOffset,
              })
            );
          }}
          room={callingState.room!}
          contentMetadata={callingState.contentMetadata}
          loadingOpen={callingState.updateRoomLoading}
        />
      ) : (
        ""
      )}
    </Stack>
  );
});

export default CallingPage;
