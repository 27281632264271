/**
 *  Classes to represent the different errors thrown by the ApiClient
 */
class GraphQLError extends Error {}
class ApiError extends Error {}
class InvalidRoomCodeError extends Error {}
class CreateRoomError extends Error {}
class UpdateRoomError extends Error {}
class UnauthorisedError extends Error {}
class NetworkError extends Error {}

export {
  GraphQLError,
  InvalidRoomCodeError,
  CreateRoomError,
  UpdateRoomError,
  UnauthorisedError,
  NetworkError,
  ApiError,
};
