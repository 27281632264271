import { QXVPApiClient } from "../api/QXVPApiClient";
import { Room } from "../types/room";
import { updateRoomApi } from "./updateRoom";
import LOG from "../logging/Logger";
import { getApiDetails } from "./getApiDetails";

export interface UpdateContentParameters {
  room: Room;
  contentUuid: string;
}
export async function updateContentApi(
  updateContentParams: UpdateContentParameters
) {
  LOG.info("Update content: " + updateContentParams.contentUuid);
  const apiDetails = getApiDetails();
  var providerId = "";
  const metadata = (await QXVPApiClient.getInstance().getMetadata(
    updateContentParams.contentUuid
  )) as any;

  LOG.info("Metadata:" + JSON.stringify(metadata));

  const svod = metadata.waystowatch?.svod;
  LOG.info("Svod: " + JSON.stringify(svod));

  if (svod && svod.length > 0) {
    providerId = svod[0].providerid;
  } else {
    throw new Error(
      "No provider id. This means that this content can't be played."
    );
  }

  await updateRoomApi({
    room: updateContentParams.room,
    entityId: updateContentParams.contentUuid,
    providerId: providerId,
    playbackOffset: new Date().getTime(),
  });
}
