import { memo, ReactNode } from "react";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";

import { Variant } from "@mui/material/styles/createTypography";

interface SectionCardProps {
  title?: string;
  variant?: Variant;
  component?: React.ElementType;
  children: ReactNode;
}

const SectionCard = memo(
  ({
    title,
    variant = "h5",
    component = Card,
    children,
    ...restProps
  }: SectionCardProps) => {
    return (
      <Box
        component={component}
        sx={{
          m: 1,
        }}
        {...getDataAttributes(restProps)}
      >
        {title && (
          <CardHeader
            title={<Typography variant={variant}>{title}</Typography>}
          />
        )}
        <CardContent>{children}</CardContent>
      </Box>
    );
  }
);

type InputPropType = {
  [key: string]: string;
};

export const getDataAttributes = (inputProps: InputPropType = {}) =>
  Object.keys(inputProps).reduce((acc: InputPropType, key) => {
    if (key.startsWith("data-")) {
      acc[key] = inputProps[key];
    }

    return acc;
  }, {});

export const convertCamelCaseToKebabCase = (name: string) =>
  name.replace(/([A-Z])/g, "-$1").toLowerCase();

export default SectionCard;
