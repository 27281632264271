import axios from "axios";
import LOG from "../logging/Logger";
class QXVPApiClient {
  private static CDN_URL = "https://myriad.merlin.xfinity.eu/select/image";
  private static SKY_PARTNER_ID = "8127520914178635105";
  private static CONTENT_METADATA_URL =
    "https://qxvp.skyq.sky.com/entity/search/v2/skyqstb/home/4101/1/user/programme/";
  private static CONTENT_SEARCH_URL =
    "https://qxvp.skyq.sky.com/suggest/v1/ethanSTB/search/4101/1/1?term=/";

  private static instance: QXVPApiClient;
  public static getInstance(): QXVPApiClient {
    if (QXVPApiClient.instance != null) {
      return this.instance;
    }
    QXVPApiClient.instance = new QXVPApiClient();
    return QXVPApiClient.instance;
  }

  async getMetadata(entityId: string) {
    try {
      const contentUrl = QXVPApiClient.CONTENT_METADATA_URL + entityId;
      const url = "https://corsproxy.io/?" + encodeURIComponent(contentUrl);
      const res = await axios.get(url, {});
      let programmeData = {};
      LOG.info("Content metadata result: " + JSON.stringify(res.data));
      
      if (res.data.programmes && res.data.programmes[0]) {
        programmeData = res.data.programmes[0];
      }
      return {
        backgroundUrl: this.getBackgroundUrl(entityId),
        ...programmeData,
      };
    } catch (error) {
      LOG.error("Failed to get metadata: " + error);
    }
  }

  getContentTypeFrom(content: String) {
    switch (content) {
      case "VOD":
        return "VOD";
      case "Service":
        return "SERVICE";
      case "Programme":
        return "PROGRAMME";
      case "IVOD":
        return "IVOD";
    }
  }

  getBackgroundUrl(entityId: string) {
    const url = new URL(QXVPApiClient.CDN_URL);
    url.searchParams.set("entityId", entityId);
    url.searchParams.set("width", "1920"); // for 4K set to 3840
    url.searchParams.set("height", "1080"); // for 4K set to 2160
    url.searchParams.set("companyId", QXVPApiClient.SKY_PARTNER_ID);
    url.searchParams.set("partnerIdentifier", "sky-uk");
    url.searchParams.set("rule", "Sky_Background");
    return url.toString();
  }

  async search(text: string) {
    const contentUrl =
      QXVPApiClient.CONTENT_SEARCH_URL + encodeURIComponent(text);
    const url = "https://corsproxy.io/?" + encodeURIComponent(contentUrl);
    const res = await axios.get(url, {});
    return res.data.results;
  }
}
export { QXVPApiClient };
