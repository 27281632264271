/**
    * Create the header properties required for API Key authentication
    * @param {String} graphQLEndpoint The graphQL endpoint
    * @param {String} apiKey The api key
    * @returns 
    */
function createAPIKeyAuthHeader(graphQLEndpoint, apiKey) {
    const urlParser = new URL(graphQLEndpoint);
    return {
        "host": urlParser.host,
        "x-api-key": apiKey,
    };
}


export {
    createAPIKeyAuthHeader
};