import { ApiClient } from "../api/ApiClient";
import LOG from "../logging/Logger";
import { getApiDetails } from "./getApiDetails";

export interface GetRoomParameters {
  roomCode: string;
}

export async function getRoomApi(getRoomParameters: GetRoomParameters) {
  LOG.info(`Get room: ${getRoomParameters.roomCode}`);
  const apiDetails = getApiDetails();
  const apiClient = ApiClient.getInstance(apiDetails);
  const input = { roomCode: getRoomParameters.roomCode };
  const result = await apiClient.getRoom(input);
  return result;
}
