import { InvalidRoomCodeError } from "../api/ApiErrors";
import LocalStorageManager from "../data/LocalStorageManager";
import { getRoomApi } from "./getRoom";
import { updateJoinRoomTries } from "./updateJoinRoomTries";

export interface CheckRoomParameters {
  roomCode: string;
}

const BLOCK_TIME_MS = 1000 * 60 * 60;

export async function checkRoomApi(joinRoomParameters: CheckRoomParameters) {
  const roomCode = joinRoomParameters.roomCode;
  const localStorageManager = LocalStorageManager.getInstance();
  var joinRoomTries = localStorageManager.getJoinRoomTries() ?? 0;
  var joinRoomBlockTime = localStorageManager.getJoinRoomBlockTime();

  if (joinRoomTries === 7) {
    if (
      joinRoomBlockTime &&
      new Date().getTime() - joinRoomBlockTime < BLOCK_TIME_MS
    ) {
      throw new Error(
        "You have reached your limit of invalid room codes. Please try again later."
      );
    } else {
      localStorageManager.setJoinRoomTries(0);
      localStorageManager.setJoinRoomBlockTime(0);
    }
  }
  if (roomCode.length !== 9) {
    updateJoinRoomTries();
    throw new Error("Invalid room code. Room code should have 9 digits.");
  }
  try {
    const result = await getRoomApi({ roomCode: roomCode });
    if (result) {
      localStorageManager.setJoinRoomTries(0);
    }
  } catch (error) {
    updateJoinRoomTries();
    if (error instanceof InvalidRoomCodeError) {
      throw new Error("Invalid room code. Check if the room exists.");
    } else {
      throw new Error("Unexpected error happend. Try again later.");
    }
  }
  return true;
}
