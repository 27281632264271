import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { initializeIcons, registerIcons } from "@fluentui/react";
import { DEFAULT_COMPONENT_ICONS } from "@azure/communication-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

console.log("Init icons");
initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });
  
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
