import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { Fragment, memo, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  JoinRoomState,
  joinRoom,
  selectJoinRoomState,
} from "./slices/joinRoomSlice";
import SectionCard from "../../components/SectionCard";
import { PlayArrow } from "@mui/icons-material";
import LoadingBackdrop from "../../components/LoadingBackdrop";

const JoinRoomPage = memo(() => {
  const joinRoomState = useAppSelector(selectJoinRoomState) as JoinRoomState;
  const dispatch = useAppDispatch();

  const [roomCode, setRoomCode] = useState("");
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    if (joinRoomState.roomConfirmed) {
      window.location.href = `/calling?roomCode=${roomCode}`;
    }
  }, [joinRoomState.roomConfirmed, roomCode]);

  // Fallback for previous WT PWA versions, if there's roomCode already in the address, we should be navigated to the room.
  useEffect(() => {
    if (!initialised) {
      setInitialised(true);
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let roomCode = params.get("roomCode") ?? "";
      console.log("roomCode: " + roomCode);
      if (roomCode) {
        setRoomCode(roomCode);
        dispatch(joinRoom(roomCode));
        console.log("navigate!");
      }
    }
  }, []);

  return (
    <Stack>
      <LoadingBackdrop open={joinRoomState.isLoading}></LoadingBackdrop>
      <Fragment>
        <SectionCard title="Join room">
          <Typography variant="h6">
            Type the room code below to join the Watch Together room.
          </Typography>
          <Box sx={{ display: "flex", marginTop: 2 }}>
            <TextField
              sx={{ flexGrow: 1 }}
              id="outlined-basic"
              label="Room code"
              variant="outlined"
              value={roomCode}
              type="number"
              error={joinRoomState.error ? true : false}
              helperText={joinRoomState.error}
              onChange={(event) => {
                if (event.target.value.length < 10) {
                  setRoomCode(event.target.value);
                }
              }}
            />
            <Button
              variant="contained"
              style={{ maxHeight: "56px" }}
              onClick={() => {
                dispatch(joinRoom(roomCode));
              }}
            >
              <PlayArrow></PlayArrow>
            </Button>
          </Box>
        </SectionCard>
      </Fragment>
    </Stack>
  );
});

export default JoinRoomPage;
