import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import callingSlice from "../pages/calling/slices/callingSlice";
import joinRoomSlice from "../pages/joinroom/slices/joinRoomSlice";

export const store = configureStore({
  reducer: {
    calling: callingSlice,
    joinRoom: joinRoomSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
