import { Fragment, memo } from "react";
import { useRouteError } from "react-router-dom";
import SectionCard from "../../components/SectionCard";
import { Typography } from "@mui/material";

const ErrorPage = memo(() => {
  const error = useRouteError() as any;

  return (
    <Fragment>
      <SectionCard title="Error">
        <Typography align="center" variant="h5">
          {error.statusText || error.message}
        </Typography>
      </SectionCard>
    </Fragment>
  );
});

export default ErrorPage;
