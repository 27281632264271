import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import LOG from "../../../logging/Logger";
import { Room } from "../../../types/room";
import { ParticipationDetails } from "../../../types/participationDetails";
import { QXVPApiClient } from "../../../api/QXVPApiClient";
import {
  updateRoomApi,
  UpdateRoomParameters,
} from "../../../usecase/updateRoom";
import { createRoomApi } from "../../../usecase/createRoom";
import { joinRoomApi, JoinRoomParameters } from "../../../usecase/joinRoom";
import {
  subscribeToRoomUpdatesApi,
  SubscribeToRoomUpdatesParameters,
} from "../../../usecase/subscribeToRoomUpdates";
import { subscribeToParticipantUpdatesApi } from "../../../usecase/subscribeToParticipantUpdates";
import { InitCallParameters } from "../../../types/initCallParameters";
import { initCallApi } from "../../../usecase/initCall";
import {
  setMicrophoneStateApi,
  SetMicrophoneStateParameters,
} from "../../../usecase/setMicrophoneState";
import {
  setCameraStateApi,
  SetCameraStateParameters,
} from "../../../usecase/setCameraState";
import { searchContetApi } from "../../../usecase/searchContent";
import { hangUpCallApi } from "../../../usecase/hangUpCall";
import { updateContentApi, UpdateContentParameters } from "../../../usecase/updateContent";
import { AddRemoteParticipantParams, AddRemoteParticipantViewParams, LocalParticipant, RemoteParticipant, RemoveRemoteParticipantParams, RemoveRemoteParticipantViewParams, SetDominantSpeakersParams } from "../../../types/participant";


export interface CallingState {
  value: number;
  status: "idle" | "connecting" | "loading" | "failed";
  room: Room | undefined;
  participationDetails: ParticipationDetails | undefined;
  localVideoStream: any | undefined;
  roomCode: string;
  microphoneEnabled: boolean;
  cameraEnabled: boolean;
  roomEvents: any[];
  participantEvents: any[];
  contentMetadata: any;
  error: string | undefined;
  loading: boolean;
  updateRoomLoading: boolean;
  callInitialised: boolean;
  roomUpdateSubscriptionInitialised: boolean;
  participantUpdateSubscriptionInitialised: boolean;
  searchContentResult: any[];
  localParticipant: LocalParticipant;
  remoteParticipants: RemoteParticipant[];
  dominantSpeakers: any[];
}

const initialState: CallingState = {
  value: 0,
  status: "idle",
  room: undefined,
  participationDetails: undefined,
  localVideoStream: undefined,
  roomCode: "",
  microphoneEnabled: true,
  cameraEnabled: true,
  roomEvents: [],
  participantEvents: [],
  contentMetadata: {},
  error: undefined,
  loading: false,
  updateRoomLoading: false,
  callInitialised: false,
  roomUpdateSubscriptionInitialised: false,
  participantUpdateSubscriptionInitialised: false,
  searchContentResult: [],
  localParticipant: { cameraEnabled: true, micEnabled: true, hasView: false },
  remoteParticipants: [],
  dominantSpeakers: [],
};

export const createRoom = createAsyncThunk(
  "calling/createRoom",
  async () => {
    return await createRoomApi();
  }
);

export const joinRoom = createAsyncThunk(
  "calling/joinRoom",
  async (joinRoomParameters: JoinRoomParameters) => {
    return await joinRoomApi(joinRoomParameters);
  }
);

export const subscribeToRoomUpdate = createAsyncThunk(
  "calling/onRoomUpdate",
  async (
    subscribeToRoomUpdatesParameters: SubscribeToRoomUpdatesParameters
  ) => {
    await subscribeToRoomUpdatesApi(subscribeToRoomUpdatesParameters);
  }
);

export const subscribeToParticipantUpdate = createAsyncThunk(
  "calling/onParticipantUpdate",
  async (
    subscribeToParticipantUpdatesParameters: SubscribeToRoomUpdatesParameters
  ) => {
    await subscribeToParticipantUpdatesApi(
      subscribeToParticipantUpdatesParameters
    );
  }
);

export const initCall = createAsyncThunk(
  "calling/initCall",
  async (initCallParameters: InitCallParameters) => {
    return initCallApi(initCallParameters);
  }
);

export const setMicrophoneState = createAsyncThunk(
  "calling/setMicrophoneState",
  async (setMicrophoneStateParameters: SetMicrophoneStateParameters) => {
    return setMicrophoneStateApi(setMicrophoneStateParameters);
  }
);

export const setCameraState = createAsyncThunk(
  "calling/setCameraState",
  async (setCameraStateParameters: SetCameraStateParameters) => {
    return setCameraStateApi(setCameraStateParameters);
  }
);

export const hangUpCall = createAsyncThunk("calling/hangUpCall", async () => {
  return hangUpCallApi();
});

export const getMetadata = createAsyncThunk(
  "calling/getMetadata",
  async (room: Room) => {
    if (room.entityId != null) {
      return await QXVPApiClient.getInstance().getMetadata(room.entityId);
    } else {
      return {};
    }
  }
);

export const updateRoom = createAsyncThunk(
  "calling/updateRoom",
  async (updateRoomParams: UpdateRoomParameters) => {
    return updateRoomApi(updateRoomParams);
  }
);

export const updateContent = createAsyncThunk(
  "calling/updateContent",
  async (updateContentParams: UpdateContentParameters, { rejectWithValue }) => {
    try {
      return updateContentApi(updateContentParams);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const searchContent = createAsyncThunk(
  "calling/searchContent",
  async (text: string) => {
    return searchContetApi(text);
  }
);

export const addLocalView = createAsyncThunk(
  "calling/addLocalView",
  async () => {
    return true;
  }
);

export const addRemoteParticipant = createAsyncThunk(
  "calling/addRemoteParticipant",
  async (params: AddRemoteParticipantParams) => {
    return params.uuid;
  }
);

export const removeRemoteParticipant = createAsyncThunk(
  "calling/removeRemoteParticipant",
  async (params: RemoveRemoteParticipantParams) => {
    return params.uuid;
  }
);

export const addRemoteParticipantView = createAsyncThunk(
  "calling/addRemoteParticipantView",
  async (params: AddRemoteParticipantViewParams) => {
    return params;
  }
);

export const removeRemoteParticipantView = createAsyncThunk(
  "calling/removeRemoteParticipantView",
  async (params: RemoveRemoteParticipantViewParams) => {
    return params;
  }
);

export const onParticipantChanged = createAsyncThunk(
  "calling/onParticipantChanged",
  async (params: any) => {
    if (params && params.data && params.data.onUpdateParticipant) {
      const uuid = params.data.onUpdateParticipant.azureUserId;
      const cameraHardwareEnabled =
        params.data.onUpdateParticipant.cameraHardwareEnabled;
      const cameraEnabled = cameraHardwareEnabled
        ? params.data.onUpdateParticipant.cameraEnabled
        : false;
      const micEnabled = cameraHardwareEnabled
        ? params.data.onUpdateParticipant.micEnabled
        : false;

      return {
        uuid: uuid,
        cameraEnabled: cameraEnabled,
        micEnabled: micEnabled,
      };
    } else {
      return {};
    }
  }
);

export const setDominantSpeaker = createAsyncThunk(
  "calling/setDominantSpeaker",
  async (params: SetDominantSpeakersParams) => {
    return params.uuids;
  }
);

export const callingSlice = createSlice({
  name: "calling",
  initialState,
  reducers: {
    setRoomCode(state, action: PayloadAction<string>) {
      LOG.info("Set room code = " + action.payload);
      state.roomCode = action.payload;
      if (!state.roomCode || state.roomCode.length == 0) {
        state.error = "Invalid room code";
      }
    },
   
    addRoomEvent(state, action: PayloadAction<any>) {
      LOG.info("Received action: " + JSON.stringify(action.payload));
      const row = action.payload.data.onUpdateRoom;

      row.time = new Date().getTime();
      state.roomEvents.push(row);

      const previousRoomState = state.room;
      const room = { previousRoomState, ...row };
      state.room = room;
      state.updateRoomLoading = false;
    },

    addParticipantEvent(state, action: PayloadAction<any>) {
      const row = action.payload.data.onUpdateParticipant;

      row.time = new Date().getTime();
      state.participantEvents.push(row);
    },
    clearError(state, action: PayloadAction<any>) {
      state.error = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(addRemoteParticipant.fulfilled, (state, action) => {
        const uuid = action.payload;
        const result = state.remoteParticipants.filter((participant) => {
          return participant.uuid === uuid;
        });
        const roomParticipantResult = state.room?.participants?.items!.filter(
          (participant: any) => {
            return participant.azureUserId === uuid;
          }
        );

        var cameraEnabled = true;
        var micEnabled = true;

        if (roomParticipantResult && roomParticipantResult.length > 0) {
          const roomParticipant = roomParticipantResult[0];
          cameraEnabled = roomParticipant.cameraHardwareEnabled
            ? roomParticipant.cameraEnabled
            : false;
          micEnabled = roomParticipant?.cameraHardwareEnabled
            ? roomParticipant.micEnabled
            : false;
        }

        if (result.length === 0) {
          state.remoteParticipants.push({
            uuid: uuid,
            hasView: false,
            cameraEnabled: cameraEnabled,
            micEnabled: micEnabled,
          });
        }
      })

      .addCase(removeRemoteParticipant.fulfilled, (state, action) => {
        const uuid = action.payload;
        const result = state.remoteParticipants.filter((participant) => {
          return participant.uuid === uuid;
        });
        if (result.length > 0) {
          state.remoteParticipants.splice(
            state.remoteParticipants.indexOf(result[0]),
            1
          );
        }
      })

      .addCase(addRemoteParticipantView.fulfilled, (state, action) => {
        const uuid = action.payload.uuid;
        const result = state.remoteParticipants.filter((participant) => {
          return participant.uuid === uuid;
        });
        if (result.length > 0) {
          result[0].hasView = true;
          state.remoteParticipants = [...state.remoteParticipants];
        }
      })

      .addCase(removeRemoteParticipantView.fulfilled, (state, action) => {
        const uuid = action.payload.uuid;
        const result = state.remoteParticipants.filter((participant) => {
          return participant.uuid === uuid;
        });
        if (result.length > 0) {
          result[0].hasView = false;
          state.remoteParticipants = [...state.remoteParticipants];
        }
      })

      .addCase(onParticipantChanged.fulfilled, (state, action) => {
        const uuid = action.payload.uuid;

        const result = state.remoteParticipants.filter((participant) => {
          return participant.uuid === uuid;
        });
        if (result.length > 0) {
          result[0].cameraEnabled = action.payload.cameraEnabled;
          result[0].micEnabled = action.payload.micEnabled;
          state.remoteParticipants = [...state.remoteParticipants];
        }

        if (uuid === state.participationDetails?.participant.azureUserId) {
          state.localParticipant.cameraEnabled = action.payload.cameraEnabled;
          state.localParticipant.micEnabled = action.payload.micEnabled;
        }
      })

      .addCase(addLocalView.fulfilled, (state, action) => {
        state.localParticipant.hasView = true;
        state.loading = false;
      })

      .addCase(setDominantSpeaker.fulfilled, (state, action) => {
        state.dominantSpeakers = action.payload;
      })

      .addCase(createRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(createRoom.fulfilled, (state, action) => {
        state.room = action.payload;
        state.loading = false;
      })
      .addCase(createRoom.rejected, (state) => {
        state.error = "Failed to create room.";
        state.loading = false;
      })
      .addCase(joinRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(joinRoom.fulfilled, (state, action) => {
        state.participationDetails = action.payload.participantDetails;
        if (action.payload.room) {
          state.room = action.payload.room;
        }
      })
      .addCase(joinRoom.rejected, (state) => {
        state.error = "Failed to join room.";
        state.loading = false;
      })

      .addCase(initCall.pending, (state) => {
        state.loading = true;
      })
      .addCase(initCall.fulfilled, (state, action) => {
        state.callInitialised = true;
      })
      .addCase(initCall.rejected, (state) => {
        state.error = "Failed to initialise call.";
        state.status = "failed";
        state.loading = false;
      })
      .addCase(setMicrophoneState.pending, (state) => {
        state.loading = true;
      })
      .addCase(setMicrophoneState.fulfilled, (state, action) => {
        state.microphoneEnabled = action.payload;
        state.loading = false;
      })
      .addCase(setMicrophoneState.rejected, (state) => {
        state.error = "Failed to set microphone state.";
        state.loading = false;
      })
      .addCase(setCameraState.pending, (state) => {
        state.loading = true;
      })
      .addCase(setCameraState.fulfilled, (state, action) => {
        state.cameraEnabled = action.payload;
        state.loading = false;
      })
      .addCase(setCameraState.rejected, (state) => {
        state.error = "Failed to set camera state.";
        state.loading = false;
      })
      .addCase(getMetadata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMetadata.fulfilled, (state, action) => {
        state.contentMetadata = action.payload;
        state.loading = false;
      })
      .addCase(getMetadata.rejected, (state) => {
        state.error = "Failed to get metadata.";
        state.loading = false;
      })

      .addCase(updateRoom.pending, (state) => {
        state.loading = true;
        state.updateRoomLoading = true;
      })
      .addCase(updateRoom.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateRoom.rejected, (state, action) => {
        const errorMessage = action.error.message ?? "";
        state.error = "Failed to update room. " + errorMessage;
        state.loading = false;
        state.updateRoomLoading = false;
      })
      .addCase(subscribeToRoomUpdate.pending, (state) => {
        //state.loading = true;
      })
      .addCase(subscribeToRoomUpdate.fulfilled, (state, action) => {
        // state.loading = false;
        state.roomUpdateSubscriptionInitialised = true;
      })
      .addCase(subscribeToRoomUpdate.rejected, (state) => {
        state.error = "Failed to register room update subscription.";
        //state.loading = false;
      })
      .addCase(subscribeToParticipantUpdate.pending, (state) => {
        //state.loading = true;
      })
      .addCase(subscribeToParticipantUpdate.fulfilled, (state, action) => {
        //state.loading = false;
        state.participantUpdateSubscriptionInitialised = true;
      })
      .addCase(subscribeToParticipantUpdate.rejected, (state) => {
        state.error = "Failed to register participant update subscription.";
        //state.loading = false;
      })

      .addCase(searchContent.pending, (state) => {
        //state.loading = true;
      })
      .addCase(searchContent.fulfilled, (state, action) => {})
      .addCase(searchContent.rejected, (state) => {
        state.error = "Failed to register participant update subscription.";
        state.loading = false;
      })
      .addCase(updateContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateContent.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateContent.rejected, (state, action) => {
        const errorMessage = action.error.message ?? "";
        state.error = "Failed to update content. " + errorMessage;
        state.loading = false;
      })
      .addCase(hangUpCall.pending, (state) => {
        state.loading = true;
      })
      .addCase(hangUpCall.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(hangUpCall.rejected, (state) => {
        state.error = "Failed to hang up.";
        state.loading = false;
      });
  },
});

export const {
  setRoomCode,
  addRoomEvent,
  addParticipantEvent,
  clearError,
} = callingSlice.actions;
export const selectCallingState = (state: RootState) => state.calling;

export default callingSlice.reducer;
