import { ApiClient } from "../api/ApiClient";
import { WTCallClient } from "../call/WTCallClient";
import { ParticipationDetails } from "../types/participationDetails";
import { getApiDetails } from "./getApiDetails";

export interface SetCameraStateParameters {
  participationDetails: ParticipationDetails;
  state: boolean;
}

export async function setCameraStateApi(
  setCameraStateParameters: SetCameraStateParameters
) {
  await WTCallClient.getInstance().setCameraState(
    setCameraStateParameters.state
  );
  const input = { cameraEnabled: setCameraStateParameters.state };
  const apiDetails = getApiDetails();
  await ApiClient.getInstance(apiDetails).updateParticipant(
    input,
    setCameraStateParameters.participationDetails.participant.id
  );
  return setCameraStateParameters.state;
}
