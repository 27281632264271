import { createBrowserRouter } from "react-router-dom";
import JoinRoomPage from "../pages/joinroom/JoinRoomPage";
import CallingPage from "../pages/calling/CallingPage";
import ErrorPage from "../pages/error/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <JoinRoomPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/calling",
    element: <CallingPage />,
  },
]);

export default router;
