import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, IconButton, Slider, Stack, Typography } from "@mui/material";
import {
  Close,
  FastForwardRounded,
  FastRewindRounded,
  PauseRounded,
  PlayArrowRounded,
} from "@mui/icons-material";
import { Room } from "../../types/room";
import { styled } from "@mui/material/styles";
import LoadingBackdrop from "../LoadingBackdrop";
export interface RemoteControlDialogProps {
  open: boolean;
  handleClose: () => void;
  room: Room;
  contentMetadata?: any;
  loadingOpen: boolean;
  onRoomChanged: (
    isPlaying?: boolean | undefined,
    playbackOffset?: number | undefined
  ) => void;
}
interface RemoteControlSeekBarProps {
  position: number;
  duration: number;
  onPositionChanged: (position: number) => void;
}

const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

function getDuration(contentMetadata?: any) {
  if (!contentMetadata) {
    return -1;
  }
  if (contentMetadata.waystowatch && contentMetadata.waystowatch.svod) {
    const svod = contentMetadata.waystowatch.svod[0];
    return svod.d;
  }
  return -1;
}

function getPosition(room: Room) {
  const currentTime = new Date().getTime();
  if (room.playbackOffset) {
    const position = currentTime - room.playbackOffset;
    return (position / 1000) | 0;
  }
  return -1;
}

function getSeriesTitle(contentMetadata: any) {
  if (contentMetadata) {
    var title = contentMetadata.seriestitle;
    if (contentMetadata.seasonnumber && contentMetadata.episodenumber) {
      title += `, S${contentMetadata.seasonnumber}:E${contentMetadata.episodenumber}`;
    }
    return title;
  }

  return "";
}
var timerId: NodeJS.Timeout | undefined;

const RemoteControlDialog = ({
  open,
  handleClose,
  room,
  contentMetadata,
  loadingOpen,
  onRoomChanged,
}: RemoteControlDialogProps) => {
  const mainIconColor = "#fff";
  const paused = !room.isPlaying;
  const duration = getDuration(contentMetadata);
  const [position, setPosition] = useState(0);
  const [seeking, setSeeking] = useState(false);

  const isLiveContent = room.liveContent === "true";
  const canShowSeekbar = !isLiveContent && position >= 0 && duration >= 0;

  useEffect(() => {
    const newPosition = getPosition(room);
    if (duration !== -1 && newPosition > duration) {
      setPosition(duration);
    } else {
      setPosition(newPosition);
    }
  }, [room, duration]);

  useEffect(() => {
    if (!canShowSeekbar || paused || seeking || !open || position >= duration) {
      return;
    }
    timerId && clearTimeout(timerId);
    timerId = undefined;
    timerId = setTimeout(() => {
      setPosition(position + 1);
    }, 1000);
  }, [paused, canShowSeekbar, position, seeking, open, duration]);

  function fastForward() {
    var offset = 15000;
    if (position + 15 >= duration) {
      offset = (duration - position) * 1000;
    }

    if (offset === 0) {
      return;
    }

    onRoomChanged(
      room.isPlaying,
      new Date().getTime() - position * 1000 - offset
    );
  }

  function fastRewind() {
    var offset = 15000;
    if (position - 15 <= 0) {
      offset = position * 1000;
    }

    if (offset === 0) {
      return;
    }

    onRoomChanged(
      room.isPlaying,
      new Date().getTime() - position * 1000 + offset
    );
  }

  function playPause() {
    onRoomChanged(!room.isPlaying, new Date().getTime() - position * 1000);
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogTitle>Remote control</DialogTitle>
        <DialogContent>
          <Stack>
            {contentMetadata && contentMetadata.backgroundUrl ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: -1,
                }}
              >
                <div>
                  <Box textAlign="center" sx={{ marginTop: 2 }}>
                    <img
                      src={contentMetadata.backgroundUrl}
                      alt=""
                      style={{ height: 200 }}
                    />
                    {contentMetadata.t ? (
                      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        {contentMetadata.t}
                      </Typography>
                    ) : (
                      ""
                    )}
                    {contentMetadata.seasontitle ? (
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        {getSeriesTitle(contentMetadata)}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                  {contentMetadata.sy ? (
                    <Typography variant="body1" align="center">
                      {contentMetadata.sy}
                    </Typography>
                  ) : (
                    ""
                  )}
                </div>
              </Box>
            ) : (
              ""
            )}

            {!isLiveContent ? (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "32px",
                    mt: -1,
                  }}
                >
                  <IconButton
                    aria-label="previous song"
                    onClick={() => {
                      fastRewind();
                    }}
                  >
                    <FastRewindRounded
                      fontSize="large"
                      htmlColor={mainIconColor}
                    />
                  </IconButton>
                  <IconButton
                    aria-label={paused ? "play" : "pause"}
                    onClick={() => playPause()}
                  >
                    {paused ? (
                      <PlayArrowRounded
                        sx={{ fontSize: "3rem" }}
                        htmlColor={mainIconColor}
                      />
                    ) : (
                      <PauseRounded
                        sx={{ fontSize: "3rem" }}
                        htmlColor={mainIconColor}
                      />
                    )}
                  </IconButton>
                  <IconButton
                    aria-label="next song"
                    onClick={() => {
                      fastForward();
                    }}
                  >
                    <FastForwardRounded
                      fontSize="large"
                      htmlColor={mainIconColor}
                    />
                  </IconButton>
                </Box>

                {canShowSeekbar ? (
                  <RemoteControlSeekBar
                    position={position}
                    duration={duration}
                    onPositionChanged={(position) => {
                      setSeeking(true);
                      setPosition(position);
                      if (timerId) {
                        clearTimeout(timerId);
                        timerId = undefined;
                      }
                      timerId = setTimeout(() => {
                        if (timerId) {
                          const currentTime = new Date().getTime();
                          const newPosition = currentTime - position * 1000;
                          onRoomChanged(room.isPlaying, newPosition);
                          setSeeking(false);
                        }
                      }, 250);
                    }}
                  />
                ) : (
                  <div />
                )}
              </Box>
            ) : (
              ""
            )}
            <LoadingBackdrop open={loadingOpen}></LoadingBackdrop>
          </Stack>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const RemoteControlSeekBar = ({
  position,
  duration,
  onPositionChanged,
}: RemoteControlSeekBarProps) => {
  function formatDuration(value: number) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  return (
    <Box>
      <Slider
        aria-label="time-indicator"
        size="small"
        value={position}
        min={0}
        step={1}
        max={duration}
        onChange={(_, value) => {
          onPositionChanged(value as number);
        }}
        sx={{
          color: "#fff",
          height: 4,
          "& .MuiSlider-thumb": {
            width: 8,
            height: 8,
            transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
            "&:before": {
              boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
            },
            "&:hover, &.Mui-focusVisible": {
              boxShadow: `0px 0px 0px 8px ${"rgb(255 255 255 / 16%)"}`,
            },
            "&.Mui-active": {
              width: 20,
              height: 20,
            },
          },
          "& .MuiSlider-rail": {
            opacity: 0.28,
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: -2,
        }}
      >
        <TinyText>{formatDuration(position)}</TinyText>
        <TinyText>-{formatDuration(duration - position)}</TinyText>
      </Box>
    </Box>
  );
};

export default RemoteControlDialog;
